export const environment = {
	production: false,
	hmr: false,
	on_premise: false,
	is_login_feedback_card_enabled: true,
	instance_url: "http://localhost:9090/",
	redirect_url: "http://localhost:4300/",
	wss_url: "ws://localhost:3600/",
	wss_agent_url: "ws://AGENT_WS_URL:3600/",
	captcha: false,
	management: false,
	test_setup: true,
	management_url: "http://localhost:4200/",
	ui_build: "SAAS_Build20240910_050009",
	is_oic_enable: false,
	oic_url: "http://localhost:9090/ux/oidc/login",
	is_nccm_enabled: false,
	nccm_url: "https://192.168.50.109/pconfmtchanges",
	sitekey: "6Ley0_oiAAAAALJ1HbRQ9sHtVAKQgKihnKjXtJtG",
	marketplace_url: "http://localhost:7070/",
	// mixpanel: "2179113511c9ef26e7cbfe3c106c73a8",
	mixpanel: "YOUR_PROD_KEY_FOR_MIXPANEL",
	recaptcha: {
		// siteKey: "6Lep8CQoAAAAALuUWvgG4KIi8k4iAbpHTW0l55-i",
		siteKey: "YOUR_SITE_KEY_FOR_RECAPTCHA",
	},
	microsoft_client_id: "e1dda86b-b3b3-4513-8e2b-f835feddaea9",
	google_client_id: "YOUR_GOOGLE_CLIENT_ID",
	is_docker: false,
};
