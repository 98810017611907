import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import store from "store";
import { Subject } from "rxjs";
import { map, takeUntil } from "rxjs/operators";

import { CoreConfigService } from "@core/services/config.service";
import { CommonService } from "app/services/common.service";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { CoreSidebarService } from "@core/components/core-sidebar/core-sidebar.service";
import { DOCUMENT } from "@angular/common";
import { NgSelectConfig } from "@ng-select/ng-select";
import { TranslateService } from "@ngx-translate/core";
import { BrandStylingService } from "app/base/brand-styling/brand-styling.service";

@Component({
	selector: "vertical-layout",
	templateUrl: "./vertical-layout.component.html",
	styleUrls: ["./vertical-layout.component.scss"],
	encapsulation: ViewEncapsulation.None,
})
export class VerticalLayoutComponent implements OnInit, OnDestroy, AfterViewInit {
	coreConfig: any;
	submenu: boolean;
	currentscreen: any;
	tempScreen: any;
	hoverClass: any;
	isAdminPortal = false;
	isInfraonAdmin = false;
	isSupportPortal = false;
	isSelfServicePortal = false;
	isMspPortal = false;
	public showSidebarDetails: any = {};
	// Private
	private _unsubscribeAll: Subject<any>;
	requesterData: any = {};

	/**
	 * Constructor
	 *
	 * @param {CoreConfigService} _coreConfigService
	 */
	constructor(
		@Inject(DOCUMENT) private document: any,
		private _coreConfigService: CoreConfigService,
		private _elementRef: ElementRef,
		private _commonServices: CommonService,
		private _router: Router,
		private _activatedRoute: ActivatedRoute,
		private _coreSidebarService: CoreSidebarService,
		private _translateService: TranslateService,
		private ngSelectConfig: NgSelectConfig,
		private _brandStylingService: BrandStylingService,
	) {
		const notFoundText = this._translateService.instant("UI.k_no_items_found");
		//	console.log("Not found TXT", notFoundText);

		this.ngSelectConfig.notFoundText = notFoundText;
		// Set the private defaults
		this._unsubscribeAll = new Subject();
		this._commonServices.fluentMenu.subscribe((val) => {
			this.hoverClass = val;
			//console.log(this.hoverClass);
		});
		this._router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				const allPath = event.url.split("/");
				const mainPath = allPath[1];
				let redirectPath = "";
				if (allPath.length > 2) {
					redirectPath = allPath[2];
				}
				let accessType = store.get("accessType");
				if (mainPath === "admin" || redirectPath === "admin") {
					this._coreConfigService.setConfig({
						layout: {
							adminPortal: true,
							layoutStyle: "fixed",
							themeColor: 'default',
							navbar: {
								type: "fixed-top",
								hidden: redirectPath.split("?").includes("payment") ? true : false,
								backgroundColor: 'bg-card',
							},
							menu: {
								collapsed: false,
								hidden: redirectPath.split("?").includes("payment") ? true : false,
								logoBackgroundColor: 'bg-card',
							},
							footer: { hidden: true, type: "footer-static" },
						},
					});
					this.isAdminPortal = true;
					this.isInfraonAdmin = false;
					this.isSupportPortal = false;
					this.isSelfServicePortal = false;
					this.isMspPortal = false;
					this._coreConfigService._selfServiceSubject.next("");
					this._coreConfigService._adminSubject.next("admin-portal");
					this._coreConfigService._supportSubject.next("");
					this._coreConfigService._mspSubject.next("");

					// }else if(mainPath === 'self-service' || accessType== 2){
				} else if (mainPath === "self-service" || accessType == 2) {
					this._coreConfigService.setConfig({
						layout: {
							layoutStyle: "fixed",
							adminPortal: false,
							supportPortal: false,
							themeColor: 'default',
							menu: {
								hidden: true,
								logoBackgroundColor: 'bg-card',
							},
							navbar: {
								type: "fixed-top",
								backgroundColor: 'bg-card',
						 	},
							footer: { hidden: false, type: "footer-sticky" },
						},
					});
					this.isAdminPortal = false;
					this.isInfraonAdmin = false;
					this.isSupportPortal = false;
					this.isSelfServicePortal = true;
					this.isMspPortal = false;
					this._coreConfigService._selfServiceSubject.next("self-service");
					this._coreConfigService._adminSubject.next("");
					this._coreConfigService._supportSubject.next("");
					this._coreConfigService._mspSubject.next("");
				} else if (mainPath === "support") {
					this._coreConfigService.setConfig({
						layout: {
							layoutStyle: "fixed",
							adminPortal: false,
							supportPortal: true,
							themeColor: 'default',
							menu: { collapsed: true, logoBackgroundColor: 'bg-card' },
							navbar: { type: "fixed-top" },
							footer: { hidden: true, type: "footer-static", backgroundColor: 'bg-card' },
						},
					});
					this.isAdminPortal = false;
					this.isInfraonAdmin = false;
					this.isSelfServicePortal = false;
					this.isSupportPortal = true;
					this.isMspPortal = false;
					this._coreConfigService._selfServiceSubject.next("");
					this._coreConfigService._adminSubject.next("");
					this._coreConfigService._supportSubject.next("support-portal");
					this._coreConfigService._mspSubject.next("");
				}else if (mainPath === "msp") {
					this._coreConfigService.setConfig({
						layout: {
							layoutStyle: "fixed",
							adminPortal: false,
							supportPortal: false,
							mspPortal:true,
							menu: { collapsed: true },
							navbar: { type: "fixed-top" },
							footer: { hidden: true, type: "footer-static" },
						},
					});
					this.isAdminPortal = false;
					this.isInfraonAdmin = false;
					this.isSelfServicePortal = false;
					this.isSupportPortal = false;
					this.isMspPortal = true;
					this._coreConfigService._selfServiceSubject.next("");
					this._coreConfigService._adminSubject.next("");
					this._coreConfigService._supportSubject.next("");
					this._coreConfigService._mspSubject.next("msp-portal");
				}
				else {
					this._brandStylingService
					.loadOrganiationConfig({ config_type: "branding" })
					.pipe(
						map((response: any) => {
							if (response) {
								this._coreConfigService.setConfig({
									layout: {
										adminPortal: false,
										supportPortal: false,
										layoutStyle: response.branding.layout_style,
										borderStyle: response.branding.border_style,
										themeColor: response.branding.theme,
										navbar: {
											backgroundColor: response.branding.navbar,
											type: "fixed-top"
										},
										menu: {
											logoBackgroundColor: response.branding.logocontainer,
											collapsed: true,
											logo: response?.branding?.logo,
										},
										footer: { hidden: true, type: "footer-static" },
									},
								})
							}
						})).subscribe();
					// this._coreConfigService.setConfig({
					// 	layout: {
					// 		adminPortal: false,
					// 		supportPortal: false,
					// 		menu: { collapsed: true },
					// 		navbar: { type: "fixed-top" },
					// 		footer: { hidden: true, type: "footer-static" },
					// 	},
					// });
					this.isAdminPortal = false;
					this.isInfraonAdmin = false;
					this.isSelfServicePortal = false;
					this.isSupportPortal = false;
					this._coreConfigService._selfServiceSubject.next("");
					this._coreConfigService._adminSubject.next("");
					this._coreConfigService._supportSubject.next("");
				}
			}
		});
		this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe((config) => {
			this.coreConfig = config;
			if (this.coreConfig.layout["adminPortal"]) {
				this.isAdminPortal = true;
				this.document.body.classList.remove("spaced-out-layout");
				this.document.body.classList.add(this.coreConfig.layout.layoutStyle + "-layout");
			} else {
				this.isAdminPortal = false;
			}
		});
		this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe((config) => {
			this.coreConfig = config;
			if (this.coreConfig.layout["supportPortal"]) {
				this.isSupportPortal = true;
				this.document.body.classList.remove("spaced-out-layout");
				this.document.body.classList.add(this.coreConfig.layout.layoutStyle + "-layout");
			} else {
				this.isSupportPortal = false;
			}
		});
	}

	// Lifecycle Hooks
	// -----------------------------------------------------------------------------------------------------

	/**
	 * On init
	 */
	ngOnInit(): void {
		// Subscribe to config changes
		this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe((config) => {
			this.coreConfig = config;
			if (!this.coreConfig.layout?.navbar?.backgroundColor) {
				// this.coreConfig.layout.navbar.backgroundColor = this.coreConfig.layout.navbar?.backgroundColor;
				if (localStorage.getItem("navbar")) {
					this.coreConfig.layout.navbar.backgroundColor = localStorage.getItem("navbar");
				}
			}
		});
		// this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe((config) => {
		// 	this.coreConfig = config;
		// 	let background = this.coreConfig.layout?.navbar?.backgroundColor;
		// 	console.log("background", background);
		// 	if (!background && background == "null") {
		// 		this.coreConfig.layout.navbar.backgroundColor = this.coreConfig.layout.navbar?.backgroundColor;
		// 	} else if (localStorage.getItem("navbar")) {
		// 		this.coreConfig.layout.navbar.backgroundColor = localStorage.getItem("navbar");
		// 	}
		// });
		this._commonServices.requester.subscribe((value: { req: any; reqProfile: any, webPhone: any, primaryNumber: any }) => {
			this.requesterData['requester'] = value?.req;
			this.requesterData['requesterProfile'] = value?.reqProfile;
			this.requesterData['webPhone'] = value?.webPhone;
			this.requesterData['primaryNumber'] = value?.primaryNumber
		});
	}
	ngAfterViewInit() {}
	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		// Unsubscribe from all subscriptions
		this._unsubscribeAll.next(undefined);
		this._unsubscribeAll.complete();
	}
	onHideGetInTouch() {
		// this.showSidebarDetails.getIntouch = true;
		this._coreSidebarService.getSidebarRegistry("get-in-touch").toggleOpen();
	}
}
